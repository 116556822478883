import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  styled,
  TablePagination,
} from "@mui/material";
import CommandeRow from "./CommandeRow"; // Composant qui affiche les détails d'une commande

const StyledTableContainer = styled(TableContainer)({
  borderRadius: "12px",
  overflow: "hidden",
  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
});

function CommandesTable({
  commandes,
  searchTerm,
  statusFilter,
  modeEnvoiFilter,
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
}) {
  const [filteredCommandes, setFilteredCommandes] = useState([]);

  // Appliquer les filtres (recherche, statut, mode d'envoi)
  useEffect(() => {
    const applyFilters = () => {
      let filtered = commandes;

      // Filtre de recherche
      if (searchTerm) {
        filtered = filtered.filter((commande) =>
          commande.numeroCommande.toLowerCase().includes(searchTerm.toLowerCase())
        );
      }

      // Filtre de statut
      if (statusFilter) {
        filtered = filtered.filter((commande) => commande.statut === statusFilter);
      }

      // Filtre de mode d'envoi
      if (modeEnvoiFilter) {
        filtered = filtered.filter((commande) => commande.modeLivraison === modeEnvoiFilter);
      }

      setFilteredCommandes(filtered); // Mise à jour des commandes filtrées
    };

    applyFilters();
  }, [commandes, searchTerm, statusFilter, modeEnvoiFilter]);

  // Pagination des commandes filtrées
  const paginatedCommandes = filteredCommandes.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <>
      <StyledTableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Date de la commande</TableCell>
              <TableCell>Numéro de commande</TableCell>
              <TableCell>Client</TableCell>
              <TableCell>Mode d'envoi</TableCell>
              <TableCell>Total Billets</TableCell>
              <TableCell>Détails des Billets</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedCommandes.length > 0 ? (
              paginatedCommandes.map((commande) => (
                <CommandeRow key={commande._id} commande={commande} />
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={8} align="center">
                  Aucune commande trouvée.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </StyledTableContainer>

      {/* Pagination */}
      <TablePagination
        component="div"
        count={filteredCommandes.length} // Nombre total après filtrage
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[10, 25, 50]}
      />
    </>
  );
}

export default CommandesTable;
