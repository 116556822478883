import React, { useState } from 'react';
import { Modal as MuiModal, Box, Typography, Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

function Modal({ open, onClose, commande, onSend, onDelete }) {
    const [isProcessing, setIsProcessing] = useState(false);
    const [isConfirming, setIsConfirming] = useState(false);
    const [error, setError] = useState(null);

    // Fonction pour envoyer ou renvoyer les billets
    const handleSendClick = () => {
        setIsConfirming(true);  // Activer la confirmation d'envoi
    };

    const handleConfirmSend = async () => {
        setIsProcessing(true);
        setError(null);
    
        try {
            // Récupérer le token JWT depuis localStorage
            const token = localStorage.getItem('token');
            
            // Appel à l'API pour envoyer ou renvoyer les billets
            const response = await fetch(`/api/commandes/${commande._id}/envoyer`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`  // Inclure le token JWT dans l'en-tête Authorization
                },
                body: JSON.stringify({ billets: commande.articles.map(article => article._id) }), // envoyer les billets si nécessaire
            });
    
            if (!response.ok) {
                throw new Error('Erreur lors de l\'envoi des billets.');
            }
    
            const data = await response.json();
            alert(data.message); // Message de succès (envoi ou renvoi)
        } catch (err) {
            setError(`Erreur lors de l'envoi : ${err.message || err}`);
        } finally {
            setIsProcessing(false);
            setIsConfirming(false);
            onClose();  // Fermer la modale après l'envoi
        }
    };
    

    const handleCancelSend = () => {
        setIsConfirming(false);  // Réinitialiser la modale sans envoyer
    };

    const handleDeleteClick = async () => {
        setIsProcessing(true);
        setError(null);
        try {
            await onDelete(commande);  // Appel à la fonction onDelete pour supprimer la commande
            alert('Commande supprimée avec succès !');
        } catch (err) {
            setError(`Erreur lors de la suppression : ${err.message || err}`);
        } finally {
            setIsProcessing(false);
            onClose();  // Fermer la modale après la suppression
        }
    };

    const getModalContent = () => {
        if (isConfirming) {
            const totalBillets = commande.articles.reduce((total, article) => total + article.quantite, 0);
            const nomEvenement = commande.articles.length > 0 ? commande.articles[0].nomArticle : 'événement';

            return (
                <>
                    <Typography variant="body1" sx={{ mb: 2 }}>
                        Souhaitez-vous envoyer {totalBillets} billet(s) pour {nomEvenement} au client {commande.acheteur} ?
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mt: 2 }}>
                        <Button variant="contained" color="primary" onClick={handleConfirmSend} disabled={isProcessing}>
                            Oui
                        </Button>
                        <Button variant="contained" color="secondary" onClick={handleCancelSend} disabled={isProcessing}>
                            Non
                        </Button>
                    </Box>
                </>
            );
        }

        // Contenu par défaut de la modal
        if (!commande || !commande.articles) {
            return <Typography variant="body2" color="error">Les détails de la commande ne sont pas disponibles.</Typography>;
        }

        const totalBillets = commande.articles.reduce((total, article) => total + article.quantite, 0);

        return (
            <>
                <Typography variant="body1" sx={{ mb: 2 }}>
                    Récapitulatif de la commande : {totalBillets} billet(s) au total.
                </Typography>
                <Box sx={{ textAlign: 'left', mb: 2 }}>
                    <Typography variant="body2" sx={{ fontWeight: 'bold', mb: 1 }}>
                        Détails des billets :
                    </Typography>
                    {commande.articles.map((article, idx) => (
                        <Typography key={idx} variant="body2">
                            {article.quantite}x {article.nomArticle} - {article.prixUnitaire} €
                        </Typography>
                    ))}
                </Box>
            </>
        );
    };

    return (
        <MuiModal open={open} onClose={onClose}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 500,
                    bgcolor: 'background.paper',
                    borderRadius: '15px',
                    boxShadow: 24,
                    p: 4,
                    textAlign: 'center',
                }}
            >
                {/* Header avec bouton de fermeture */}
                <IconButton onClick={onClose} sx={{ position: 'absolute', top: 8, right: 8, color: '#555' }}>
                    <CloseIcon />
                </IconButton>
                <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                    Récapitulatif de la commande
                </Typography>

                {/* Contenu dynamique de la modal */}
                {getModalContent()}

                {/* Boutons d'action : Envoyer et Supprimer */}
                {!isConfirming && (
                    <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mt: 2 }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSendClick}
                            disabled={isProcessing}
                        >
                            {isProcessing ? 'Envoi en cours...' : 'Envoyer'}
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={handleDeleteClick}
                            disabled={isProcessing}
                        >
                            {isProcessing ? 'Suppression en cours...' : 'Supprimer'}
                        </Button>
                    </Box>
                )}

                {/* Message d'erreur */}
                {error && (
                    <Typography variant="body2" color="error" sx={{ mt: 2 }}>
                        {error}
                    </Typography>
                )}
            </Box>
        </MuiModal>
    );
}

export default Modal;
