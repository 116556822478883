import React, { useState, useEffect } from "react";
import { Tabs, Tab, Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Commande from "./Commande"; // Importation du composant Commande
import Stock from "./Stock"; // Importation du composant Stock

const Dashboard = () => {
  const [tabValue, setTabValue] = useState(0); // État pour gérer l'onglet actif
  const navigate = useNavigate(); // Initialiser useNavigate pour la navigation

  // Vérification si l'utilisateur est connecté (si le token existe)
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/'); // Rediriger vers la page d'accueil si aucun token n'est présent
    }
  }, [navigate]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue); // Mise à jour de l'onglet actif
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Typography variant="h4" gutterBottom>
        Tableau de Bord
      </Typography>
      <Tabs value={tabValue} onChange={handleTabChange} centered>
        <Tab label="Commandes" />
        <Tab label="Stock" />
      </Tabs>

      {/* Contenu de l'onglet Commande */}
      {tabValue === 0 && (
        <Box sx={{ p: 3 }}>
          <Commande /> {/* Utilisation du composant Commande avec les filtres */}
        </Box>
      )}

      {/* Contenu de l'onglet Stock */}
      {tabValue === 1 && (
        <Box sx={{ p: 3 }}>
          <Stock />
        </Box>
      )}
    </Box>
  );
};

export default Dashboard;
