import { useState, useEffect } from 'react';
import axios from 'axios';

function useBilletsData() {
  const [megaramaStock, setMegaramaStock] = useState([]);
  const [familyStock, setFamilyStock] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchStocks = async () => {
      try {
        const token = localStorage.getItem('token'); // Récupérer le token JWT du localStorage

        // Récupérer les billets assignés et disponibles pour Megarama et Family
        const [megaramaResponse, familyResponse] = await Promise.all([
          axios.get('/api/billets/megarama/stock', {
            headers: {
              Authorization: `Bearer ${token}`,  // Envoyer le token JWT dans l'en-tête Authorization
            },
          }),
          axios.get('/api/billets/family/stock', {
            headers: {
              Authorization: `Bearer ${token}`,  // Envoyer le token JWT dans l'en-tête Authorization
            },
          }),
        ]);

        

        // Mettre à jour les stocks dans les états
        setMegaramaStock(megaramaResponse.data.stock);
        setFamilyStock(familyResponse.data.stock);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchStocks();
  }, []);

  return { megaramaStock, familyStock, loading, error };
}

export default useBilletsData;
