import React, { useState } from "react";
import { Box, Typography, Button, styled } from "@mui/material";
import BilletImportModal from "../Common/BilletImportModal";
import useBilletsData from "../../hooks/useBilletsData";

// Styles personnalisés pour les compteurs
const CountersContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "20px",
  backgroundColor: "#f5f5f5",
  borderRadius: "12px",
  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
  marginTop: "20px",
});

const Counter = styled(Box)({
  textAlign: "center",
  backgroundColor: "#fff",
  padding: "15px",
  borderRadius: "12px",
  boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.05)",
  minWidth: "150px",
});

const AddButton = styled(Button)({
  padding: "10px 20px",
  fontSize: "1rem",
  backgroundColor: "#1976d2",
  color: "#fff",
  fontWeight: "bold",
  borderRadius: "8px",
  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
  "&:hover": {
    backgroundColor: "#1565c0",
  },
});

const CompteurBillets = () => {
  // Utilisation du hook useBilletsData, exactement comme dans Stock.js
  const { megaramaStock, familyStock, loading, error } = useBilletsData();
  const [isBilletImportModalOpen, setIsBilletImportModalOpen] = useState(false);

  const handleOpenBilletImportModal = () => setIsBilletImportModalOpen(true);
  const handleCloseBilletImportModal = () => setIsBilletImportModalOpen(false);

  // Compter uniquement les billets disponibles
  const countBilletsDisponibles = (billets) => {
    return billets.filter((billet) => billet.statut === "Disponible").length;
  };

  const megaramaDisponibles = countBilletsDisponibles(megaramaStock);
  const familyDisponibles = countBilletsDisponibles(familyStock);

  if (loading) {
    return <Typography>Chargement des stocks...</Typography>;
  }

  if (error) {
    return <Typography>Erreur lors du chargement des stocks : {error}</Typography>;
  }

  return (
    <CountersContainer>
      <Counter>
        <Typography variant="h6">Megarama</Typography>
        <Typography variant="h4">{megaramaDisponibles}</Typography>
        <Typography variant="body2">Billets disponibles</Typography>
      </Counter>
      <Counter>
        <Typography variant="h6">Family Cinema</Typography>
        <Typography variant="h4">{familyDisponibles}</Typography>
        <Typography variant="body2">Billets disponibles</Typography>
      </Counter>
      <AddButton onClick={handleOpenBilletImportModal}>
        Ajouter Billets
      </AddButton>

      {/* Modal pour l'importation des billets */}
      <BilletImportModal
        open={isBilletImportModalOpen}
        onClose={handleCloseBilletImportModal}
      />
    </CountersContainer>
  );
};

export default CompteurBillets;
