import React, { createContext, useState, useEffect } from 'react';

// Créer le contexte
export const AuthContext = createContext();

// Provider du contexte
export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  // Vérifier si un token est présent dans le localStorage au chargement initial
  useEffect(() => {
    const token = localStorage.getItem('token');
    setIsLoggedIn(!!token); // Si le token est présent, l'utilisateur est connecté
  }, []);

  // Fonction pour mettre à jour l'état lorsqu'on se connecte/déconnecte
  const login = () => {
    setIsLoggedIn(true);
  };

  const logout = () => {
    localStorage.removeItem('token'); // Supprimer le token
    setIsLoggedIn(false);
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
