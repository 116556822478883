import React, { useState, useEffect } from "react";
import { Box, Typography, styled } from "@mui/material";
import axios from "axios";
import FilterControls from "./Common/FilterControls"; // Composant pour la barre de recherche et les filtres
import CommandesTable from "./Commandes/CommandesTable"; // Tableau des commandes
import BilletImportModal from "./Common/BilletImportModal";
import CompteurBillets from "./Billets/CompteurBillets"; // Compteur des billets disponibles

const DashboardContainer = styled(Box)({
  background: "#ffffff",
  minHeight: "100vh",
  padding: "20px",
  color: "#333333",
  position: "relative",
  fontFamily: "'Roboto', sans-serif",
});

function Commande() {
  const [commandes, setCommandes] = useState([]); // Contient toutes les commandes récupérées
  const [searchTerm, setSearchTerm] = useState(""); // Contient la valeur de la recherche
  const [statusFilter, setStatusFilter] = useState(""); // Contient la valeur du filtre de statut
  const [modeEnvoiFilter, setModeEnvoiFilter] = useState(""); // Contient la valeur du filtre de mode d'envoi
  const [page, setPage] = useState(0); // Gère la pagination
  const [rowsPerPage, setRowsPerPage] = useState(10); // Gère les lignes par page
  const [isBilletImportModalOpen, setIsBilletImportModalOpen] = useState(false); // Modal pour importer des billets

  // Fonction pour récupérer les commandes
  useEffect(() => {
    const fetchCommandes = async () => {
      try {
        const token = localStorage.getItem("token"); // Récupérer le token JWT du localStorage

        const response = await axios.get("/api/commandes", {
          headers: {
            Authorization: `Bearer ${token}`, // Envoyer le token JWT dans l'en-tête Authorization
          },
        });

        if (Array.isArray(response.data)) {
          setCommandes(response.data); // Si les données sont un tableau, les stocker
        } else {
          setCommandes([]); // Sinon, initialiser avec un tableau vide
        }
      } catch (err) {
        setCommandes([]); // En cas d'erreur, initialiser avec un tableau vide
      }
    };

    fetchCommandes(); // Appel initial pour récupérer les commandes
  }, []);

  // Compter les billets dans les commandes
  const countBillets = () => {
    let megaramaCount = 0;
    let familyCount = 0;

    commandes.forEach((commande) => {
      commande.articles.forEach((article) => {
        if (article.nomArticle === "Billet MEGARAMA") {
          megaramaCount += article.quantite;
        } else if (article.nomArticle === "Billet FAMILY") {
          familyCount += article.quantite;
        }
      });
    });

    return { megaramaCount, familyCount };
  };

  const { megaramaCount, familyCount } = countBillets();

  return (
    <DashboardContainer>
      <Typography variant="h4" gutterBottom>
        Tableau des Billets Disponibles
      </Typography>

      {/* Composant de contrôle de filtre */}
      <FilterControls
        searchTerm={searchTerm}
        statusFilter={statusFilter}
        modeEnvoiFilter={modeEnvoiFilter}
        handleSearch={(e) => setSearchTerm(e.target.value)}
        handleStatusFilter={(e) => setStatusFilter(e.target.value)}
        handleModeEnvoiFilter={(e) => setModeEnvoiFilter(e.target.value)}
      />

      {/* Compteur des billets disponibles */}
      <CompteurBillets megaramaStock={megaramaCount} familyStock={familyCount} />

      {/* Tableau des commandes avec filtres et pagination */}
      <CommandesTable
        commandes={commandes}
        searchTerm={searchTerm}
        statusFilter={statusFilter}
        modeEnvoiFilter={modeEnvoiFilter}
        page={page}
        rowsPerPage={rowsPerPage}
        handleChangePage={(e, newPage) => setPage(newPage)}
        handleChangeRowsPerPage={(e) => setRowsPerPage(parseInt(e.target.value, 10))}
      />

      {/* Modal d'import de billets */}
      <BilletImportModal open={isBilletImportModalOpen} onClose={() => setIsBilletImportModalOpen(false)} />
    </DashboardContainer>
  );
}

export default Commande;
