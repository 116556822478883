import React from "react";
import { Box, TextField, Select, MenuItem, FormControl, InputLabel, styled } from "@mui/material";

const FilterContainer = styled(Box)({
  display: "flex",
  gap: "20px",
  marginBottom: "20px",
});

const StyledTextField = styled(TextField)({
  marginBottom: "20px",
  "& .MuiOutlinedInput-root": {
    borderRadius: "12px",
  },
});

function FilterControls({
  searchTerm,
  statusFilter,
  modeEnvoiFilter,
  handleSearch,
  handleStatusFilter,
  handleModeEnvoiFilter,
}) {
  return (
    <FilterContainer>
      {/* Champ de recherche */}
      <StyledTextField
        label="Rechercher une commande"
        variant="outlined"
        fullWidth
        value={searchTerm}
        onChange={handleSearch} // Mise à jour correcte de searchTerm dans Commande
      />

      {/* Filtre sur le statut */}
      <FormControl variant="outlined" fullWidth>
        <InputLabel>Status</InputLabel>
        <Select
          value={statusFilter}
          onChange={handleStatusFilter} // Mise à jour correcte de statusFilter dans Commande
          label="Status"
        >
          <MenuItem value="">Tous</MenuItem>
          <MenuItem value="En attente">En attente</MenuItem>
          <MenuItem value="Expédié">Expédié</MenuItem>
        </Select>
      </FormControl>

      {/* Filtre sur le mode d'envoi */}
      <FormControl variant="outlined" fullWidth>
        <InputLabel>Mode Envoi</InputLabel>
        <Select
          value={modeEnvoiFilter}
          onChange={handleModeEnvoiFilter} // Mise à jour correcte de modeEnvoiFilter dans Commande
          label="Mode Envoi"
        >
          <MenuItem value="">Tous</MenuItem>
          <MenuItem value="Email">Email</MenuItem>
          <MenuItem value="Guichet">Guichet</MenuItem>
          <MenuItem value="Postal">Postal</MenuItem>
        </Select>
      </FormControl>
    </FilterContainer>
  );
}

export default FilterControls;
