import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Box,
} from "@mui/material";
import { Menu as MenuIcon, Close as CloseIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

function Navbar() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false); // État pour savoir si l'utilisateur est connecté
  const navigate = useNavigate();

  // Vérifier si l'utilisateur est connecté (c'est-à-dire s'il y a un token JWT)
  useEffect(() => {
    const token = localStorage.getItem('token');
    setIsLoggedIn(!!token); // Si un token est présent, l'utilisateur est connecté
  }, []);

  // Handler pour ouvrir et fermer le drawer (menu mobile)
  const toggleDrawer = (open) => {
    setDrawerOpen(open);
  };

  // Fonction pour naviguer en changeant directement l'URL
  const handleNavigate = (path) => {
    navigate(path);
    setDrawerOpen(false);
  };

  // Fonction pour déconnecter l'utilisateur
  const handleLogout = () => {
    localStorage.removeItem('token'); // Supprimer le token JWT
    setIsLoggedIn(false); // Mettre à jour l'état de connexion
    navigate('/'); // Rediriger vers la page d'accueil après déconnexion
  };

  return (
    <AppBar
      position="sticky"
      sx={{
        backgroundColor: "white",
        color: "black",
        boxShadow: "none",
        padding: "10px",
      }}
    >
      <Toolbar>
        {/* Logo */}
        <Typography
          variant="h6"
          sx={{ flexGrow: 1, fontWeight: "bold", cursor: "pointer", color: "black" }}
          onClick={() => handleNavigate("/")} // Naviguer vers l'accueil
        >
          Inter CE 42
        </Typography>

        {/* Si l'utilisateur est connecté, afficher le bouton Déconnexion */}
        {isLoggedIn && (
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <Button
              onClick={handleLogout}
              sx={{
                backgroundColor: "black", // Fond noir
                color: "white", // Texte blanc
                margin: "0 10px",
                fontWeight: "bold",
                border: "1px solid black", // Optionnel, bordure noire
                "&:hover": {
                  backgroundColor: "gray", // Fond gris lors du survol
                },
              }}
            >
              Déconnexion
            </Button>
          </Box>
        )}

        {/* Menu pour mobiles */}
        <IconButton
          edge="end"
          aria-label="menu"
          onClick={() => toggleDrawer(true)}
          sx={{ display: { xs: "block", md: "none" }, color: "black" }}
        >
          <MenuIcon />
        </IconButton>

        {/* Drawer pour mobile (Sidebar) */}
        <Drawer
          anchor="right"
          open={drawerOpen}
          onClose={() => toggleDrawer(false)}
        >
          <Box
            sx={{ width: 250 }}
            role="presentation"
            onClick={() => toggleDrawer(false)}
            onKeyDown={() => toggleDrawer(false)}
          >
            <IconButton onClick={() => toggleDrawer(false)}>
              <CloseIcon />
            </IconButton>
            <List>
              {/* Afficher le bouton Déconnexion dans la Sidebar si l'utilisateur est connecté */}
              {isLoggedIn && (
                <ListItem button onClick={handleLogout}>
                  <ListItemText
                    primary="Déconnexion"
                    sx={{
                      color: "white",
                      backgroundColor: "black", // Fond noir dans la sidebar
                      padding: "10px", // Espacement interne
                      borderRadius: "5px", // Optionnel : arrondir les bords
                    }}
                  />
                </ListItem>
              )}
            </List>
          </Box>
        </Drawer>
      </Toolbar>
    </AppBar>
  );
}

export default Navbar;
