import React, { useState } from "react";
import {
  Container,
  Typography,
  CircularProgress,
  Alert,
  Tabs,
  Tab,
  Box,
  Grid,
  Paper,
  Button,
  TextField,
} from "@mui/material";
import useBilletsData from "../hooks/useBilletsData";

function Stock() {
  const { megaramaStock, familyStock, loading, error } = useBilletsData();
  const [activeTab, setActiveTab] = useState(0);
  const [detailsOpen, setDetailsOpen] = useState({});  // Gérer l'état d'affichage des détails pour chaque billet
  const [searchTerm, setSearchTerm] = useState('');  // Ajouter un champ pour la recherche du numéro de commande

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  // Fonction pour récupérer les détails de la commande
  const fetchCommandeDetails = async (commandeId, billetId) => {
    try {
      const token = localStorage.getItem('token');  // Récupérer le token JWT depuis localStorage

      // Inclure le token JWT dans l'en-tête Authorization de la requête
      const response = await fetch(`/api/commandes/${commandeId}`, {
        headers: {
          Authorization: `Bearer ${token}`,  // Envoyer le token JWT
        },
      });

      if (!response.ok) {
        throw new Error('Erreur lors de la récupération de la commande.');
      }
      const data = await response.json();
      // Ouvrir les détails pour ce billet spécifique
      setDetailsOpen((prevState) => ({
        ...prevState,
        [billetId]: data.commande,  // Stocker les détails de la commande pour le billet spécifique
      }));
    } catch (error) {
      console.error('Erreur lors de la récupération des détails de la commande :', error);
    }
  };

  const handleCloseDetails = (billetId) => {
    // Fermer les détails pour le billet spécifique
    setDetailsOpen((prevState) => ({
      ...prevState,
      [billetId]: null,
    }));
  };

  if (loading) {
    return (
      <Container>
        <CircularProgress />
        <Typography>Chargement des billets...</Typography>
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <Alert severity="error">
          Erreur lors du chargement des billets : {error}
        </Alert>
      </Container>
    );
  }

  // Séparation des billets disponibles et assignés
  const countBillets = (billets) => {
    const disponibles = billets.filter((billet) => billet.statut === "Disponible").length;
    const assignes = billets.filter((billet) => billet.statut === "Assigné").length;
    return { disponibles, assignes };
  };

  const megaramaCount = countBillets(megaramaStock);
  const familyCount = countBillets(familyStock);

  // Filtrer les billets par statut
  const filterBillets = (billets) => {
    if (!searchTerm) return billets;
    return billets.filter((billet) => billet.commande && billet.commande.numeroCommande.toLowerCase().includes(searchTerm.toLowerCase()));
  };

  // Rendu des détails des billets avec un bouton "Détails" pour les billets assignés
  const renderBilletDetails = (billet) => {
    const isAssigned = billet.statut === "Assigné";
    const showDetails = detailsOpen[billet._id];  // Vérifier si les détails de ce billet sont affichés

    return (
      <Paper
        key={billet._id}
        elevation={3}
        sx={{
          padding: 2,
          marginBottom: 2,
          backgroundColor: isAssigned ? "rgba(76, 175, 80, 0.1)" : "inherit", // Vert clair pour les billets assignés
          borderLeft: isAssigned ? "5px solid rgba(76, 175, 80, 0.5)" : "none", // Bordure subtile pour différencier
        }}
      >
        <Typography variant="h6">{billet.nomEvenement}</Typography>
        <Typography variant="body2">Date d'expiration : {new Date(billet.dateExpiration).toLocaleDateString()}</Typography>
        <Typography variant="body2">Clé Web : {billet.cleWeb}</Typography>
        <Typography variant="body2">Numéro de billet : {billet.numeroBillet}</Typography>
        <Typography variant="body2">Statut du billet : {billet.statut}</Typography>

        {/* Ajout du numéro de commande si billet est assigné */}
        {isAssigned && billet.commande && (
          <Typography variant="body2" sx={{ mt: 1 }}>
            Numéro de commande : {billet.commande.numeroCommande}
          </Typography>
        )}

        {/* Bouton "Détails" pour les billets assignés */}
        {isAssigned && !showDetails && (
          <Box mt={2}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => fetchCommandeDetails(billet.commande._id, billet._id)}
            >
              Détails
            </Button>
          </Box>
        )}

        {/* Affichage des détails de la commande uniquement pour ce billet */}
        {showDetails && (
          <Box mt={2}>
            <Typography variant="h6">Détails de la commande</Typography>
            <Typography><strong>Numéro de commande :</strong> {showDetails.numeroCommande}</Typography>
            <Typography><strong>Acheteur :</strong> {showDetails.acheteur}</Typography>
            <Typography><strong>Email :</strong> {showDetails.email}</Typography>
            <Typography><strong>Mode de livraison :</strong> {showDetails.modeLivraison}</Typography>
            <Typography><strong>Adresse de livraison :</strong> {showDetails.adresseLivraison}</Typography>
            <Typography><strong>Date de la commande :</strong> {new Date(showDetails.dateCommande).toLocaleDateString()}</Typography>
            <Typography><strong>Total commande :</strong> {showDetails.totalCommande}€</Typography>

            {/* Liste des articles */}
            <Typography variant="h6" gutterBottom sx={{ marginTop: 2 }}>Articles :</Typography>
            {showDetails.articles.map((article, index) => (
              <Typography key={index}>
                {article.quantite}x {article.nomArticle} - {article.prixUnitaire}€
              </Typography>
            ))}

            {/* Bouton pour fermer les détails */}
            <Button variant="contained" color="secondary" onClick={() => handleCloseDetails(billet._id)} sx={{ mt: 2 }}>
              Fermer les détails
            </Button>
          </Box>
        )}
      </Paper>
    );
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Gestion du Stock
      </Typography>

      {/* Champ de recherche pour le numéro de commande */}
      <TextField
        label="Rechercher par numéro de commande"
        variant="outlined"
        fullWidth
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        sx={{ marginBottom: 4 }}
      />

      {/* Bloc récapitulatif */}
      <Box mb={4}>
        <Grid container spacing={2}>
          {/* Récapitulatif Megarama */}
          <Grid item xs={12} md={6}>
            <Box p={2} border="1px solid #ccc">
              <Typography variant="h6">Megarama</Typography>
              <Typography variant="body1">
                Billets disponibles : {megaramaCount.disponibles}
              </Typography>
              <Typography variant="body1">
                Billets assignés : {megaramaCount.assignes}
              </Typography>
            </Box>
          </Grid>
          {/* Récapitulatif Family */}
          <Grid item xs={12} md={6}>
            <Box p={2} border="1px solid #ccc">
              <Typography variant="h6">Family</Typography>
              <Typography variant="body1">
                Billets disponibles : {familyCount.disponibles}
              </Typography>
              <Typography variant="body1">
                Billets assignés : {familyCount.assignes}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* Onglets pour Megarama et Family */}
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab label="Megarama" />
        <Tab label="Family" />
      </Tabs>

      <Box mt={4}>
        {activeTab === 0 ? (
          <Box>
            {filterBillets(megaramaStock).length > 0 ? (
              filterBillets(megaramaStock).map(renderBilletDetails)
            ) : (
              <Typography>Aucun billet Megarama disponible.</Typography>
            )}
          </Box>
        ) : (
          <Box>
            {filterBillets(familyStock).length > 0 ? (
              filterBillets(familyStock).map(renderBilletDetails)
            ) : (
              <Typography>Aucun billet Family disponible.</Typography>
            )}
          </Box>
        )}
      </Box>
    </Container>
  );
}

export default Stock;
