import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  styled,
} from "@mui/material";
import * as XLSX from "xlsx";
import axios from "axios";
import moment from "moment"; // Importation de moment.js

// Styles pour les boutons d'upload
const UploadButton = styled(Button)({
  padding: "10px 20px",
  fontSize: "1rem",
  backgroundColor: "#1976d2",
  color: "#fff",
  fontWeight: "bold",
  borderRadius: "8px",
  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
  "&:hover": {
    backgroundColor: "#1565c0",
  },
  margin: "10px",
});

function BilletModal({ open, onClose }) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [eventType, setEventType] = useState(""); // Pour stocker le choix de l'utilisateur (MEGARAMA ou FAMILY Cinema)
  const [parsedData, setParsedData] = useState([]);

  // Réinitialiser l'état lors de la fermeture de la modale
  const handleClose = () => {
    setEventType("");
    setSelectedFile(null);
    setParsedData([]);
    onClose();
  };

  // Fonction pour gérer la sélection du fichier
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
    readExcelFile(file);
  };

  // Fonction pour convertir les valeurs numériques en dates avec moment.js
  const convertExcelDateToJSDate = (excelDate) => {
    if (typeof excelDate === "number") {
      const jsDate = moment(new Date((excelDate - 25569) * 86400 * 1000)); // Conversion depuis la date Excel avec moment.js
      return jsDate.isValid() ? jsDate.format("YYYY-MM-DD") : null; // Formatage en "YYYY-MM-DD" ou null si invalide
    } else if (typeof excelDate === "string") {
      const parsedDate = moment(excelDate, [
        "MM/DD/YYYY",
        "YYYY-MM-DD",
        "DD-MM-YYYY",
        "DD/MM/YYYY",
      ]);
      return parsedDate.isValid() ? parsedDate.format("YYYY-MM-DD") : null;
    }
    return null; // Retourne null si la conversion échoue
  };

  // Fonction pour lire le fichier Excel et afficher les données dans la console
  const readExcelFile = (file) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: "array" });

      // Sélectionner la première feuille de calcul du fichier Excel
      const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData = XLSX.utils.sheet_to_json(firstSheet, { header: 1 });

      // Mapper les données extraites et ajouter le champ nomEvenement en fonction de l'eventType sélectionné
      const formattedData = jsonData.slice(1).map((row) => {
        const dateExpiration = convertExcelDateToJSDate(row[2]);
        return {
          categorie: row[0] || "N/A", // Catégorie
          nomEvenement: eventType, // Dépend de l'événement choisi
          dateExpiration: dateExpiration || "1970-01-01", // Date d'expiration
          prixPlace: row[3] ? parseFloat(row[3]) : 0, // Prix de la place
          frais: row[4] ? parseFloat(row[4]) : 0, // Frais
          prixTotal: row[5] ? parseFloat(row[5]) : 0, // Prix total
          cleWeb: row[6] ? row[6].toString() : "N/A", // Clé Web unique
          numeroBillet: row[7] ? row[7].toString() : "N/A", // Numéro de billet
          statut: "Disponible", // Statut par défaut
        };
      });

      setParsedData(formattedData);
    };
    reader.readAsArrayBuffer(file);
  };

  // Fonction pour envoyer les données extraites au backend
  const handleUpload = async () => {
    if (!parsedData || parsedData.length === 0) {
      alert("Aucune donnée à envoyer.");
      return;
    }

    try {
      // Récupérer le token JWT stocké dans le localStorage
      const token = localStorage.getItem('token');
      if (!token) {
        alert("Vous devez être connecté pour importer des billets.");
        return;
      }

      const response = await axios.post(
        `/api/billets/import`,
        { billets: parsedData },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`, // Ajout du token JWT ici
          },
        }
      );

      // Vérifier que les propriétés billetsImportes et billetsIgnores existent
      const billetsImportes = response.data.billetsImportes || [];
      const billetsIgnores = response.data.billetsIgnores || [];

      // Logique d'affichage des messages basée sur les résultats
      if (billetsImportes.length === 0 && billetsIgnores.length > 0) {
        alert("ERREUR : Les billets existent déjà dans la base de données.");
      } else if (billetsImportes.length > 0 && billetsIgnores.length > 0) {
        alert(
          `${billetsImportes.length} billets ont été importés avec succès et ${billetsIgnores.length} billets existent déjà dans la base de données.`
        );
      } else if (billetsImportes.length > 0 && billetsIgnores.length === 0) {
        alert(
          `Tous les billets ont été importés avec succès : ${billetsImportes.length}`
        );
      }

      handleClose(); // Réinitialiser la modale après le succès ou l'échec partiel
    } catch (error) {
      console.error("Erreur lors de l'importation des billets :", error);

      // Gestion des erreurs améliorée
      if (error.response) {
        // Si l'erreur provient du backend, afficher un message approprié
        alert(
          `ERREUR : Un ou plusieurs billets existent déjà dans la base de données.`
        );
      } else if (error.request) {
        alert("Aucune réponse du serveur, veuillez réessayer plus tard.");
      } else {
        alert(`Erreur lors de la requête : ${error.message}`);
      }
    }
};


  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Importer des billets</DialogTitle>
      <DialogContent>
        {!eventType ? (
          <Box>
            <Typography variant="body1" gutterBottom>
              Sélectionnez l'événement pour lequel vous souhaitez ajouter des
              billets.
            </Typography>
            <UploadButton onClick={() => setEventType("MEGARAMA")}>
              Ajouter Megarama
            </UploadButton>
            <UploadButton onClick={() => setEventType("FAMILY Cinema")}>
              Ajouter Family
            </UploadButton>
          </Box>
        ) : (
          <Box>
            <Typography variant="body1" gutterBottom>
              Téléchargez le fichier Excel pour {eventType}.
            </Typography>
            <input
              type="file"
              accept=".xlsx, .xls"
              onChange={handleFileChange}
              style={{ marginTop: "10px" }}
            />
            {selectedFile && (
              <Typography variant="body2" gutterBottom>
                Fichier sélectionné : {selectedFile.name}
              </Typography>
            )}
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        {eventType && (
          <Button
            onClick={handleUpload}
            color="primary"
            disabled={!parsedData.length}
          >
            Envoyer
          </Button>
        )}
        <Button onClick={handleClose} color="secondary">
          Fermer
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default BilletModal;
