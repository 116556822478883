import React, { useState, useEffect } from "react";
import { Button, TableCell, TableRow } from "@mui/material";
import Modal from "../Common/Modal";

function CommandeRow({ commande }) {
  const [openModal, setOpenModal] = useState(false);
  const [detailsBillets, setDetailsBillets] = useState({
    megarama: 0,
    family: 0,
  });

  useEffect(() => {
    const megaramaBillets = commande.articles.filter(
      (article) => article.nomArticle === "Billet MEGARAMA"
    );
    const familyBillets = commande.articles.filter(
      (article) => article.nomArticle === "Billet FAMILY"
    );

    setDetailsBillets({
      megarama: megaramaBillets.reduce(
        (total, billet) => total + billet.quantite,
        0
      ),
      family: familyBillets.reduce(
        (total, billet) => total + billet.quantite,
        0
      ),
    });
  }, [commande]);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      <TableRow>
        {/* Aucune ligne vide entre les balises */}
        <TableCell>{new Date(commande.dateCommande).toLocaleDateString()}</TableCell>
        <TableCell>{commande.numeroCommande}</TableCell>
        <TableCell>{commande.acheteur}</TableCell>
        <TableCell>{commande.modeLivraison}</TableCell>
        <TableCell>{commande.articles.reduce((total, article) => total + article.quantite, 0)}</TableCell>
        <TableCell>
          {/* Utilisation de <span> sans ligne vide */}
          <span>{detailsBillets.megarama}x Megarama</span>
          <br />
          <span>{detailsBillets.family}x Family</span>
        </TableCell>
        <TableCell>{commande.statut}</TableCell>
        <TableCell>
          <Button variant="contained" color="primary" onClick={handleOpenModal}>
            Traiter
          </Button>
        </TableCell>
      </TableRow>

      {/* Modale pour afficher les détails des billets */}
      <Modal open={openModal} onClose={handleCloseModal} commande={commande} />
    </>
  );
}

export default CommandeRow;
