import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Dashboard from "./components/Dashboard";
import Navbar from "./components/Common/Navbar";
import Home from './components/Home';
import { AuthProvider } from './AuthContext'; // Importer le AuthProvider

function App() {
  return (
    <AuthProvider> {/* Utiliser le AuthProvider pour fournir le contexte */}
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/dashboard" element={<Dashboard />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
